import styled from 'styled-components';

import { px2rem, space, mq } from '../../stylesheets/tools';
import { theme } from '../../tokens';

const StyledHeader = styled.header`
  width: 100%;
  max-width: ${px2rem(theme.maxWidth)};
  position: relative;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  padding-top: ${space(2)};
  padding-bottom: ${space(2)};

  ${mq('m')} {
    padding-top: ${space(5)};
    padding-bottom: ${space(5)};
  }

  .header__logo {
    margin-left: auto;
    margin-right: auto;

    svg {
      width: ${px2rem(60)};
      height: ${px2rem(70)};
    }

    ${mq('m')} {
      svg {
        width: ${px2rem(82)};
        height: ${px2rem(106)};
      }
    }
  }
`;

export default StyledHeader;
