import styled from 'styled-components';

import { space, color, mq, getGrid, px2rem } from '../../stylesheets/tools';

const StyledFooter = styled.footer`
  margin-top: auto;
  width: 100%;
  z-index: 10;
  overflow: hidden;

  .footer__logo {
    margin: ${space(6)} auto;

    svg {
      width: ${px2rem(60)};
      height: ${px2rem(70)};
    }

    ${mq('m')} {
      margin: ${space(8)} auto;
      svg {
        width: ${px2rem(82)};
        height: ${px2rem(106)};
      }
    }
  }

  .footer__wrapper {
    background: ${color('blue', 'color')};
    color: ${color('neutral', 'shade')};

    ${getGrid()};
    height: ${space(10)};
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;

    ${mq('m')} {
      justify-content: flex-end;
    }
  }

  .footer__main-navigation,
  .footer__secondary-navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 ${space(1)};

    ${mq('l')} {
      margin: 0 ${space(6)};
    }
  }

  .footer__main-navigation {
    grid-column: 1 / span 12;

    ${mq('m')} {
      grid-column: 5 / span 8;
    }
    ${mq('l')} {
      grid-column: 5 / span 8;
    }
  }

  .footer__secondary-navigation {
    grid-column: 1 / span 12;
    ${mq('m')} {
      justify-content: flex-start;
      grid-column: 1 / span 4;
    }
    ${mq('l')} {
      grid-column: 1 / span 4;
    }
  }

  .footer__link-item {
    margin-right: ${space(2)};

    &:last-child {
      margin-right: 0;
    }

    a:hover {
      text-decoration: underline;
    }

    a:hover,
    a:active,
    a:focus {
      color: ${color('neutral', 'shade')};
    }

    p {
      text-align: center;

      ${mq('l')} {
        text-align: left;
      }
    }
  }
`;

export default StyledFooter;
