import React from 'react';
import styled from 'styled-components';

import { getTypo } from '../../stylesheets/tools';

type TypoProps = {
  as?: 'p' | 'span' | 'cite' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'figcaption';
  html?: string;
  children?: Node | string | JSX.Element;
  transform?: 'uppercase' | 'lowercase';
  className?: string;
  align?: 'left' | 'right' | 'center';
  type?:
    | 'HeadlineHero'
    | 'HeadlinePrimary'
    | 'HeadlineSecondary'
    | 'HeadlineTertiary'
    | 'Copy'
    | 'CopyBig'
    | 'CopyCaps'
    | 'CopySmall';
};

const StyledTypo = styled.p<TypoProps>`
  margin: 0;
  padding: 0;
  text-align: ${props => props.align};
  ${props => props.type && getTypo[props.type]}
`;

const Typo = ({
  as,
  children,
  html,
  className,
  transform,
  type,
  align
}: TypoProps): JSX.Element => {
  if (html) {
    return (
      <StyledTypo
        as={as}
        className={className}
        transform={transform}
        type={type}
        align={align}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  }

  return (
    <StyledTypo as={as} className={className} transform={transform} type={type} align={align}>
      {children}
    </StyledTypo>
  );
};

Typo.defaultProps = {
  html: null,
  children: null,
  as: 'p',
  className: '',
  align: 'left',
  transform: null,
  type: 'Copy'
};

export default Typo;
