/* eslint-disable global-require, import/no-dynamic-require, @typescript-eslint/no-var-requires */
import React from 'react';

import StyledIcon from './Icon.style';

export type IconTypes = 'back-small' | 'back' | 'quote' | 'logo' | 'milk-shape-01';

export type IconProps = { icon: IconTypes; size?: number; className?: string; inline?: boolean };

const Icon = ({ icon, size = 48, className, inline = false }: IconProps): JSX.Element => {
  const SVG = typeof icon === 'object' ? icon : require(`./icons/${icon}.svg`).default;

  return (
    <StyledIcon size={size} className={className} inline={inline}>
      <SVG />
    </StyledIcon>
  );
};

export default Icon;
