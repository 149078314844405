import styled, { css } from 'styled-components';

import { getTypo, color, mq, space, px2rem } from '../../stylesheets/tools';
import { RichttextProps } from './Richtext';

const StyledRichtext = styled.div<RichttextProps>`
  ${({ hasGrid }) =>
    hasGrid &&
    css`
      grid-column: 1 / span 12;
      margin-bottom: ${space(4)};

      ${mq('m')} {
        grid-column: 3 / span 8;
      }

      ${mq('l')} {
        grid-column: 4 / span 6;
        margin-bottom: ${space(8)};
      }

      &:last-child {
        margin-bottom: 0;
      }
    `}

  h1 {
    ${getTypo.HeadlinePrimary};
  }

  h2 {
    ${getTypo.HeadlineSecondary};
  }

  h3 {
    ${getTypo.HeadlineTertiary};
  }

  small {
    ${getTypo.CopySmall};
  }

  p + h3 {
    margin-top: ${space(5)};
  }

  p {
    ${getTypo.Copy};
  }

  ul,
  ol {
    ${getTypo.Copy};
    padding-inline-start: 0;
    list-style-type: none;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  li {
    position: relative;
    margin-left: ${space(2)};
    margin-bottom: ${space(3)};

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    li:before {
      position: absolute;
      background-color: ${color('blue', 'color')};
      content: '';
      left: ${space(-2)};
      width: ${px2rem(8)};
      height: ${px2rem(8)};
      border-radius: 50%;
      top: 10px;
    }

    ul {
      li:first-child {
        margin-top: ${space(2)};
      }
    }
  }

  ol {
    counter-reset: section;

    li:before {
      font-weight: bold;
      position: absolute;
      top: 0;
      left: ${space(-3)};
      counter-increment: section;
      content: counters(section, '.') '.';
    }

    ol {
      counter-reset: section;
      margin-bottom: 0;

      li:first-child {
        margin-top: ${space(2)};
      }

      li::before {
        counter-increment: section;
        content: counter(section, lower-alpha);
      }
    }
  }

  h2,
  h3,
  p,
  ul,
  ol,
  small {
    margin: 0;
    margin-bottom: ${space(2)};

    ${mq('l')} {
      margin-bottom: ${space(3)};
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a,
  em,
  b,
  strong {
    font-weight: bold;
  }

  em.highlight {
    font-style: normal;
  }

  a {
    border-bottom: 2px solid ${color('blue', 'color')};
    color: ${color('blue', 'color')};
    text-decoration: none;
  }
`;

export default StyledRichtext;
