import styled from 'styled-components';

const StyledIcon = styled.div<{ size: number; inline: boolean }>`
  width: max-content;
  height: max-content;
  display: ${props => (props.inline ? 'inline-block' : 'block')};
  svg {
    width: ${props => props.size}px;
    height: ${props => props.size}px;
  }
`;

export default StyledIcon;
