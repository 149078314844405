import React, { ReactNode } from 'react';

import StyledRichtext from './Richtext.style';

export type RichttextProps = {
  as?: 'div' | 'figcaption';
  html?: string;
  children?: ReactNode;
  className?: string;
  hasGrid?: boolean;
};

const Richtext = ({
  as = 'div',
  html,
  children,
  hasGrid = false,
  className
}: RichttextProps): JSX.Element => {
  if (html) {
    return (
      <StyledRichtext
        as={as}
        className={className}
        hasGrid={hasGrid}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  }

  return (
    <StyledRichtext as={as} className={className} hasGrid={hasGrid}>
      {children}
    </StyledRichtext>
  );
};

export default Richtext;
