/* eslint-disable react/prop-types */
// Component with CSS Grid Container based on grid config from theme
import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { getGrid, getMaxWidthContainer } from '../../stylesheets/tools';

export type GridProps = {
  children: ReactNode;
  className?: string;
  hasGridPaddings?: boolean;
};

const StyledGrid = styled.div<GridProps>`
  ${getMaxWidthContainer()};

  .grid__wrapper {
    position: relative;
    ${props => getGrid(props.hasGridPaddings)};
  }
`;

const Grid = React.forwardRef<HTMLInputElement, GridProps>(
  ({ children, className = '', hasGridPaddings = true }, ref) => (
    <StyledGrid className={className} hasGridPaddings={hasGridPaddings} ref={ref}>
      <div className="grid__wrapper">{children}</div>
    </StyledGrid>
  )
);

export default Grid;
