import React, { ReactNode } from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

const defaultProps = {
  withHeader: true
};

type DefaultTemplateProps = {
  children: ReactNode;
  withHeader?: boolean;
} & typeof defaultProps;

const Default = ({ children, withHeader }: DefaultTemplateProps): JSX.Element => {
  const footerLinks = [
    { href: '/datenschutz', text: 'datenschutz' },
    { href: '/impressum', text: 'impressum' }
  ];

  return (
    <>
      <script
        src="https://consent.cookiefirst.com/banner.js"
        data-cookiefirst-key="03e389bf-d6f4-470a-99f8-27e059c44cd1"
        data-silent-mode="true"
      />

      {withHeader && <Header />}
      {children}
      <Footer footerLinks={footerLinks} />
    </>
  );
};

Default.defaultProps = defaultProps;

export default Default;
