import React from 'react';

import Link from 'next/link';

import StyledFooter from './Footer.style';
import Typo from '../Typo';
import Icon from '../Icon';

export type FooterProps = { footerLinks: Array<{ href: string; text: string }> };

const Footer = ({ footerLinks }: FooterProps): JSX.Element => (
  <StyledFooter>
    <Link href="/">
      <a title="Initiative Milch">
        <Icon icon="logo" className="footer__logo" />
      </a>
    </Link>
    <div className="footer__wrapper">
      <div className="footer__secondary-navigation">
        <Typo type="CopyCaps">©Initiative Milch, 2021</Typo>
      </div>
      <div className="footer__main-navigation">
        <ul>
          {footerLinks.map(
            link =>
              link && (
                <li className="footer__link-item" key={link.href}>
                  <Link href={link.href}>
                    <a>
                      <Typo type="CopyCaps">{link.text}</Typo>
                    </a>
                  </Link>
                </li>
              )
          )}
        </ul>
      </div>
    </div>
  </StyledFooter>
);

export default Footer;
