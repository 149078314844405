import React from 'react';

import Link from 'next/link';

import StyledHeader from './Header.style';
import Icon from '../Icon';

type HeaderProps = {
  className?: string;
};

const Header = ({ className = '' }: HeaderProps): any => (
  <StyledHeader className={className}>
    <Link href="/">
      <a title="Initiative Milch">
        <Icon icon="logo" className="header__logo" />
      </a>
    </Link>
  </StyledHeader>
);

export default Header;
